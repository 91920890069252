* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@import "./imports/breakpoints";

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

$primary-color: #0ab;

.site-logo {
  height: 40px;
  margin-right: 0.5rem;
}
.site-sidebar {
  position: sticky;
  top: 48px;
  padding: 1.2rem 0rem;
  overflow-y: scroll;
  width: 100%;
  height: calc(100vh - (108px + 2.4rem));

  &::-webkit-scrollbar {
    display: none;
    width: 0px;
  }

  &--title {
    font-size: 0.8125rem;
    color: rgb(136, 136, 136);
    text-transform: uppercase;
    letter-spacing: 1.3px;
    transition: all 0.2s ease 0s;
    margin-bottom: 0.875rem;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
      "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
  }

  &--lists {
    margin-bottom: 1.2rem;
    list-style: none;

    a {
      display: block;
      text-decoration: none;
      color: #333;
      font-weight: 600;
      padding: 0.5rem 0rem;
      padding-left: 0.5rem;
      margin-bottom: 0.5rem;

      &:hover,
      &.active {
        background-color: #f1f1f1;
        border-radius: 5px;
        color: #111;
      }
    }
  }
}

.site-footer {
  text-align: center;
  p {
    font-size: 14px;
    color: #111;
  }
  a {
    text-decoration: none;
    color: #111;
  }
}

.site-main {
  height: 100%;
  padding: 1.2rem 0rem;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
    width: 0px;
  }
}

.suprim-site--nav {
  display: flex;
  align-items: center;
  margin-top: 1rem;

  .suprim-ui-wrapper,
  .suprim-ui-wrapper-fluid {
    display: flex;
    align-items: center;
  }

  &--logo {
    display: flex;
    align-items: center;
    font-size: 1rem;
    font-weight: 600;
    white-space: nowrap;
  }

  &--links {
    display: flex;
    align-items: center;
    list-style: none;

    &.right {
      justify-content: flex-end;
      width: 100%;
    }

    li {
      height: 100%;
      display: flex;

      a {
        padding: 0.45rem 2rem;
        font-size: 1rem;
        font-weight: 600;
        text-decoration: none;
        color: #333;
        margin-right: 0.5rem;
      }
    }
  }

  &.nav-line {
    position: relative;

    .wrapper,
    .wrapper-fluid {
      display: flex;
      align-items: center;
      height: 100%;
    }

    &::before {
      position: absolute;
      content: "";
      height: 1px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      background-color: rgb(234, 234, 234);
    }

    .nav-line-marker {
      position: absolute;
      content: "";
      width: 50px;
      height: 2px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      background-color: #000;
    }

    .suprim-site--nav--links {
      display: flex;
      align-items: center;
      list-style: none;
      height: 100%;

      &.right {
        justify-content: flex-end;
        width: 100%;
      }

      li {
        height: 100%;
        display: flex;
        align-items: center;
        position: relative;

        a {
          padding: 0.5rem 1rem;
          padding-right: 1rem;
          font-size: 1rem;
          font-weight: 600;
          text-decoration: none;
          color: #333;
          height: 100%;

          &::after {
            position: absolute;
            content: "";
            width: auto;
            height: 2px;
            left: 0px;
            right: 0px;
            bottom: 0px;
            background-color: transparent;
            transform: scaleX(0.7);
            transition: all 200ms ease 0s;
          }

          &.active::after {
            position: absolute;
            content: "";
            width: auto;
            height: 2px;
            left: 0px;
            right: 0px;
            bottom: 0px;
            transform: scaleX(1);
            background-color: #000;
          }
        }

        &:first-child a {
          padding: 0.5rem 0rem;
          padding-right: 1rem;
        }
      }
    }
  }
}

.playground {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #eaeaea;
  margin: 1.5rem 0rem;

  &.grid {
    .playground-content {
      .live-preview {
        display: grid;
        gap: 5px;
        grid-template-columns: repeat(4, 1fr);
      }
    }
  }

  &-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 16pt;

    &.grid {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
    }
  }

  &-editor {
    &--content {
      pre {
        word-break: break-all;
        white-space: pre-line;
      }

      code {
        font-size: 1rem;
        word-break: break-all;
      }
    }

    &--header {
      display: flex;
      background-color: #fafafa;
      border: none;
      display: flex;
      -webkit-box-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      align-items: center;
      color: rgb(102, 102, 102);
      height: 2.875rem;
      user-select: none;
      padding: 0px 16pt;
      border-top: 1px solid rgb(234, 234, 234);
      list-style: none;
      outline: none;
      cursor: pointer;

      &-item {
        display: flex;
        align-items: center;
        font-size: 0.875rem;
        white-space: nowrap;
        width: 100%;

        &.flex-start {
          justify-content: flex-start;
        }

        &.flex-end {
          justify-content: flex-end;
        }

        svg {
          height: 16px;
          width: 16px;
          margin-right: 0.5rem;
        }
      }
    }
  }
}

body.darkmode {
  // a,
  // p {
  //   color: #f1f1f1;
  // }

  .site-sidebar--lists a{
    color: #f1f1f1;
  }
  .suprim-site--nav.nav-line .suprim-site--nav--links li a {
    color: #f1f1f1;
  }

  .suprim-site--nav--links li a {
    color: #f1f1f1;
  }

  .suprim-site--nav.nav-line .suprim-site--nav--links li a.active::after {
    background-color: #fff;
  }

  .suprim-site--nav.nav-line::before {
    background-color: #ddd;
  }

  .playground-editor--header {
    background-color: #272727;
    color: #fff;
  }

  .site-sidebar--lists a:hover,
  .site-sidebar--lists a.active {
    background-color: #272727;
    color: #fff;
  }

  &::-webkit-scrollbar {
    background-color: #333;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #111;
  }
}

.site-app {
  @include media("<=768px") {
    .component-sidebar {
      display: none;
    }
  }
}

.icons-gallery-content {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-auto-rows: 150px;
  gap: 10px;
}

.icons-gallery--item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 0px !important;
  cursor: pointer;
  &-content {
    display: flex;
    align-items: center;
    flex-direction: column;
    p {
      color: #333;
      margin-top: 1rem;
    }
  }
}

.icons-gallery--search {
  margin-bottom: 1rem;
  input {
    height: 40px;
    border-radius: 5px;
    border: 1px solid #ddd;
    width: 100%;
    padding: 0.5rem 1rem;
    outline: none;
    font-size: 1rem;
    background-color: inherit;
  }
  &.dark {
    input {
      color: #fff;
    }
  }
}

.colors-cards {
  border: 1px solid #ddd;
  border-radius: 5px;
  overflow: hidden;
}

.colors-card {
  padding: 1.2rem;
  height: 120px;
  &.invert {
    h3,
    p {
      color: #fff;
    }
  }
  &.dark {
    &.invert {
      h3,
      p {
        color: #000;
      }
    }
  }
  &.dark-invert {
    h3,
    p {
      color: #000;
    }
    &.invert {
      h3,
      p {
        color: #fff;
      }
    }
  }
}

.comp-lists {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  gap: 1rem;
  li a {
    text-decoration: none;
  }
}

.icon-comp{
  position: relative;
  display: block;
  .badge{
    position: absolute;
    right: -15px;
    top: -15px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    border: 2px dashed  #000;
    z-index: 2;
    font-weight: bold;
    font-size: 0.875rem;
  }
}